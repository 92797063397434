import React, { useState, useEffect } from 'react';
import barCouncilLogo from '../../assets/home/logos/barCouncil-logo.webp';
import ThemeToggle from '../Theme/ThemeToggle';
import { Button } from '../ui/button';
import { MenuIcon, X } from 'lucide-react';
import LanguageSwitcher from '../ui/language-switcher';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const NewNavBar = () => {
  const { t } = useTranslation();
  const links = [
    { path: '/', label: t('Home') },
    { path: '/about', label: t('About') },
    { path: '/circulars-orders', label: t('Orders') },
    { path: '/keyforms', label: t('Forms') },
    { path: '/faq', label: t('FAQs') },
    { path: '/contact-us', label: t('Contact') },
    { path: '/how-it-works', label: t('How It Works') },
    // { path: 'https://user.advocateswelfarefund.in/auth/signup', label: t('Register') },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = window.innerHeight * 0.1;
    setIsScrolled(scrollTop > threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`w-full z-50 fixed top-0 transition-colors duration-200 
      ${
        isScrolled
          ? 'bg-white text-black border-b border-[#E5E5E5]'
          : 'bg-transparent text-white'
      } 
      ${isMenuOpen ? ' bg-white backdrop-blur-md' : ''}`}
      style={{ opacity: 1 }}
    >
      <div className="flex justify-between py-4 px-4 lg:px-16 2xl:px-11 items-center">
        <a
          aria-label="Homepage"
          href="/"
          className="flex align-middle items-center w-40 lg:w-52"
        >
          <img
            width={21}
            height={21}
            alt="barCouncil-logo"
            src={barCouncilLogo}
          />
          <p
            className={`ml-2 text-sm font-bold
          ${isMenuOpen ? 'text-black' : ''}
          ${isScrolled ? 'text-black' : ''}
          `}
          >
            AWF
          </p>
        </a>
        <nav className="hidden md:flex items-center gap-4">
          <ThemeToggle />
          <LanguageSwitcher />
          {links.map((link) => (
            <a
              key={link.path}
              href={link.path}
              className="cursor-pointer py-2 px-2 lg:px-3 xl:px-4 flex items-center text-sm lg:text-base"
            >
              {link.label}
            </a>
          ))}
          <a href="https://user.advocateswelfarefund.in/pay">
            <Button>{t('Subscription Payment')}</Button>
          </a>
        </nav>
        <div className="md:hidden flex items-center">
          <ThemeToggle />
          <LanguageSwitcher />
          {/* hamburger menu */}
          <div
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="ml-4 cursor-pointer"
          >
            {isMenuOpen ? (
              <X className="text-foreground" />
            ) : (
              <MenuIcon strokeWidth={1.45} />
            )}
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden h-screen flex flex-col bg-white/90 backdrop-blur-md shadow-lg">
          {links.map((link) => (
            link.label !== 'Register' && (
            <a
              key={link.path}
              href={link.path}
              className="p-4 border-b cursor-pointer text-foreground"
              >
              {link.label}
              </a>
            )
          ))}
          <a
            href="https://user.advocateswelfarefund.in/pay"
            className="p-4 cursor-pointer text-foreground"
          >
            {t('Subscription Payment')}
          </a>
        </div>
      )}
    </header>
  );
};

export default NewNavBar;
